import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import IndivConference from "./IndivConference";

import { StyledDisplay2 } from "../shared/StyledTypography";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

const conferencesState = [
  {
    imgSrc:
      "https://res.cloudinary.com/hasura-cms-uploads/image/upload/v1707811668/hascon_24_eventcard_1_09f17627c1.png",
    title: "HasuraCon 2024",
    linkUrl: "https://hasura.io/events/hasura-con-2024",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1686026244/hasura-con-2023/has-con-2023_a5va2o.png",
    title: "HasuraCon 2023",
    linkUrl: "https://hasura.io/events/hasura-con-2023/",
  },
  {
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/tools/hasuracon22.png",
    title: "Hasura Con’22",
    linkUrl: "/events/hasura-con-2022/",
  },
  {
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/tools/enterprisegraphql21.png",
    title: "Enterprise GraphQL Conference’21 (EGC)",
    linkUrl: "/enterprisegraphql/",
  },
  {
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/tools/hasuracon21.png",
    title: "Hasura Con’21",
    linkUrl: "/events/hasura-con-2021/",
    externalLink: true,
  },
  {
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/tools/graphqlasia21.png",
    title: "GraphQL Asia’21",
    linkUrl: "https://graphql.asia/",
    externalLink: true,
  },
  {
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/tools/hasuracon20.png",
    title: "Hasura Con’20",
    linkUrl: "/events/hasura-con-2020/",
  },
  {
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/tools/enterprisegraphql20.png",
    title: "Enterprise GraphQL Conference’20 (EGC)",
    linkUrl: "/enterprisegraphql/2020/",
  },
];

const StyledBgAnimation = styled.div`
  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  .bgAnimationConf {
    background-image: url("https://graphql-engine-cdn.hasura.io/assets/main-site/tools/conference-bg.png");
    background-repeat: no-repeat;
    animation: gradient 15s ease infinite;
    background-size: 130%;
  }
`;

const StyledConferenceHeroBanner = styled.div`
  .mWd800 {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  .toolsListWrapper {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
    grid-gap: 32px;
    padding-top: 60px;
  }
  ${mq.between("md", "lg")} {
  }
  ${mq.below.md} {
  }
`;

const ConferenceHeroBanner = props => {
  return (
    <StyledBgAnimation>
      <StyledSectionWrapper className="bgAnimationConf" css={removePaddBottom}>
        <StyledContainerWrapper>
          <StyledConferenceHeroBanner>
            <StyledDisplay2 className="mWd800">Conferences hosted by Hasura</StyledDisplay2>
            <div className="toolsListWrapper">
              {conferencesState.map((conference, index) => (
                <IndivConference key={index} conference={conference} />
              ))}
            </div>
          </StyledConferenceHeroBanner>
        </StyledContainerWrapper>
      </StyledSectionWrapper>
    </StyledBgAnimation>
  );
};

export default ConferenceHeroBanner;

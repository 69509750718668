import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ConferenceHeroBanner from "../components/tools/ConferenceHeroBanner";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/conferences.png",
};

const canonicalUrl = "https://hasura.io/conferences/";

const Conferences = props => (
  <Layout location={props.location}>
    <Seo
      title="Conferences hosted by Hasura"
      description="All the community and GraphQL conferences hosted by Hasura"
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <ConferenceHeroBanner location={props.location} />
  </Layout>
);

export default Conferences;

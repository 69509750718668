import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Icon } from '../../globals/icons';
import { StyledDisplay2, StyledSubTitle2, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";
import { COLORS } from "../../globals/designSystem";
import { transition } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

const StyledLink = styled(props => <Link {...props} />)`
  ${transition};
  border-radius: 8px;
  .arrow {
    ${transition};
  }
  &:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 10px 15px rgba(0, 0, 0, 0.1);
  }
  .conferenceImg {
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  .p32 {
    padding: 32px;
  }
`;

const IndivConference = ({ conference }) => {

  return (
    <StyledLink to={conference.linkUrl} target={conference.externalLink ? "_blank" : "_self"} rel="noreferrer noopener">
      <div className="conferenceImg">
        <img src={conference.imgSrc} alt={conference.title} loading="lazy" />
      </div>
    </StyledLink>
  );
};

export default IndivConference;
